export default function(error) {
  const validator = error.$validator
  const params = error.$params
  switch (validator) {
    case 'required':
      return '欄位必填'
    case 'url':
      return '請輸入網址'
    case 'email':
      return '請輸入 Email 格式'
    case 'alphaNum':
      return '僅能輸入英文數字'
    case 'minLength':
      return '至少輸入 ' + params.min + ' 字元'
    case 'maxLength':
      return '最多 ' + params.max + ' 字元'
    case 'checksize':
      return '檔案大小超過規定'
    case 'numeric':
      return '僅能輸入數字'
  }
  return ''
}

<template>
  <div :class="col">
    <label :class="labelcol" v-text="label"></label>
    <div :class="inputcol">
      <input
        v-model="name"
        :class="{ 'is-invalid': v.$error }"
        :type="type"
        :maxlength="maxlength"
        class="form-control"
        :autofocus="autofocus"
        autocomplete="off"
      />
      <div v-if="v.$error" class="invalid-feedback">
        {{ msg(v.$errors[0]) }}
      </div>
      <div v-if="helper" class="form-text" v-text="helper"></div>
    </div>
  </div>
</template>

<script>
import message from '@/helper/useVuelidate.js'
export default {
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    col: {
      type: String,
      default: 'col-md-12'
    },
    labelcol: {
      type: String,
      default: 'form-label'
    },
    inputcol: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    helper: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    maxlength: {
      type: Number,
      default: 20
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    v: {
      type: Object,
      required: true
    }
  },
  emits: ['update:modelValue'],
  computed: {
    name: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    }
  },
  methods: {
    msg(s) {
      return message(s)
    }
  }
}
</script>
